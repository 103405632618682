import LocalStorage from "config/LocalStorage";
import DV from "variables/DV";
import Types from "redux/type";
import { closeAll } from "redux/actionCreator/actionSelectInbox";

//todo: handle load data
export const loading = (type, getMore = false) => {
    return {
        type,
        getMore,
    };
};

export const getSuccess = (payload, type, getMore = false) => {
    return { type: type + "_SUCCESS", payload, getMore };
};

export const getError = (payload, type, getMore = false) => {
    return { type: type + "_FAIL", payload, getMore };
};

export const getData = (
    func,
    type,
    obj = { id: null, params: "", data: null },
    getMore = false
) => {
    return (dispatch) => {
        dispatch(loading(type, getMore));
        return func(obj)
            .then((data) => {
                if (data instanceof Array) data = { data };
                dispatch(getSuccess(data, type, getMore));
            })
            .catch((errorMsg) => {
                dispatch(getError(errorMsg, type, getMore));
            });
    };
};

export const handleConnection = (payload) => {
    return (dispatch) => {
        dispatch({ type: Types.SET_NETWORK, payload });
    };
};

export const changeCompany = (payload) => {
    DV.company = payload;
    LocalStorage.setCompany(DV.company);
    const listInboxSelected = LocalStorage.getListInboxSelected();
    LocalStorage.setCompany(payload);
    window.location.reload();
    // return (dispatch) =>
    // {
    //     dispatch(closeAll());
    //     dispatch({type: Types.SELECT_INBOX, listInboxSelected});
    //     dispatch({type: Types.SET_COMPANY, payload});
    // };
};

export const selectPreset = (payload) => {
    return (dispatch) => {
        dispatch({ type: Types.SET_SELECTED_PRESET, payload });
    };
};

export const showInboxSource = (payload) => {
    return (dispatch) => {
        dispatch({ type: Types.SHOW_SOURCE_INBOX, payload });
    };
};

export const setInboxReplying = (payload) => {
    return (dispatch) => {
        dispatch({ type: Types.SET_INBOX_REPLYING, payload });
    };
};


