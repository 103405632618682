import React from "react";
import AssetImage from "../assets/AssetImage";
import { DOOPAGE_DASHBOARD } from "../config/Env";
import get from "lodash/get";
import { trans } from "config/LinguiConfig";
import { t } from "@lingui/macro";

//todo: debounce
export const DEBOUNCE_TIME = 400;
//todo: base object redux
export const getBaseReduxObj = () => ({
    data: [],
    loading: false,
    error: null,
    isSuccess: null, //todo null -> not loading yet, true -> loading success, false -> loading error
});

//todo: state socket
export const STATE_SOCKET = {
    none: "none",
    pending: "pending",
    connect: "connect",
    close: "close",
};

export const DATE_FORMAT = "ll";
export const TIME_FORMAT = "LT";

//todo: check OS
const {detect} = require("detect-browser");
export const OS = detect() ? detect().os : "NA";
export const mobileOS = [
    "iOS",
    "Android OS",
    "BlackBerry OS",
    "Windows Mobile",
    "Amazon OS",
];
export const isMobileOS = mobileOS.includes(OS);

//todo: color
export const PRIMARY_COLOR = "#ec971f";
export const SECONDARY_COLOR = "#faebcc";

//todo: config size item
export const ITEM_HEIGHT = 48;
export const WIDTH_LIST_INBOX = 350;
export const WIDTH_PRESETS = 50;
export const HEIGHT = window.innerHeight;
export const WIDTH = window.innerWidth;
export const WIDTH_LOGO = 280;
export const MIN_CHAT_WIDTH = 300;
export const MIN_CHAT_PANEL = 1;
export const MIN_WINDOW_WIDTH =
    50 + WIDTH_LIST_INBOX + MIN_CHAT_WIDTH * MIN_CHAT_PANEL;

//todo: alert time
export const ALERT_SHOW_TIME = 3; //1s
export const ALERT_ERROR_SHOW_TIME = 20;

//todo: package.json, get app version
var pjson = require("../../package.json");
export const APP_VERSION = pjson.version;
export const PAGINNG = {
    size: 5,
    head: "Đầu",
    tall: "Cuối",
    hidden: "...",
    next: "Sau",
    previous: "Trước",
};

export const ASIGN_SEGMENT = {
    ["Sắp mua hàng"]: "info",
    ["Dò giá"]: "rose",
    ["Hẹn lại"]: "warning",
};

export const SPACE = <span>&nbsp;</span>;

export const LIST_DOMAIN = {
    "hcmpost.vn": {
        logo: AssetImage.VnPostLogo,
        headerLogo: AssetImage.VnPostHeaderLogo,
        dashboardUrl: "https://shop.hcmpost.vn",
        indexTitle: "VNPOST Bán hàng",
        indexLogo: "/vnpost.jpg",
    },
    "etop.vn": {
        logo: AssetImage.TopshipLogo,
        headerLogo: AssetImage.TopshipHeaderLogo,
        dashboardUrl: DOOPAGE_DASHBOARD,
        indexTitle: "DooPage Center",
        indexLogo: "/doopage.ico",
    },
    "itop.website": {
        logo: AssetImage.iTopLogo,
        headerLogo: AssetImage.iTopHeaderLogo,
        dashboardUrl: "https://bot.itop.website",
        indexTitle: "Inbox iTop",
        indexLogo: "/itop_logo.png",
    },
    "tatisale.com": {
        logo: AssetImage.TatisaleLogo,
        headerLogo: AssetImage.TatisaleHeaderLogo,
        dashboardUrl: "https://my.tatisale.com",
        indexTitle: "Inbox Tatisale",
        indexLogo: "/tatisale_logo.png",
    },
    "atpcare.vn": {
        logo: AssetImage.ATPCareLogo,
        headerLogo: AssetImage.ATPCareHeaderLogo,
        dashboardUrl: "https://my.atpcare.vn",
        indexTitle: "Inbox ATPCare",
        indexLogo: "/atpcare_logo.png",
    },
	"moma.vn": {
        logo: AssetImage.MomaLogo,
        headerLogo: AssetImage.MomaHeaderLogo,
        dashboardUrl: "https://chat.moma.vn",
        indexTitle: "Inbox Moma",
        indexLogo: "/moma_logo.png",
    },
	"kidofa.vn": {
        logo: AssetImage.KidofaLogo,
        headerLogo: AssetImage.KidofaHeaderLogo,
        headerLogoBackgroundColor: '#ed1c24',
        dashboardUrl: "https://my.kidofa.vn",
        indexTitle: "KIDOFA Center",
        indexLogo: "/kidofa_logo.png",
    },
	"cnvinbox.com": {
        logo: AssetImage.CNVInboxLogo,
        headerLogo: AssetImage.CNVInboxHeaderLogo,
        headerLogoBackgroundColor: '#f282a2',
        secondaryColor: '#fee9ef',
        dashboardUrl: "https://app.cnvinbox.com",
        indexTitle: "CNV Inbox",
        indexLogo: "/cnvinbox_logo.png",
    },
    "": {
        logo: AssetImage.DoopageLogo,
        headerLogo: AssetImage.DoopageHeaderLogo,
        dashboardUrl: DOOPAGE_DASHBOARD,
        indexTitle: "DooPage Center",
        indexLogo: "/doopage.ico",
    },
};

export const PERMISSION_NAME = {
    seller: "seller",
    stock_keeper: "stock_keeper",
    marketer: "marketer",
    manager: "manager",
    owner: "owner",
};

export const ALL_PERMISSION = Object.keys(PERMISSION_NAME).map(
    (key) => PERMISSION_NAME[key]
);

export const mapShipmentServiceIcon = {
    topship: AssetImage.TopshipLogo,
    vtpost: AssetImage.VTPOST,
    ghn: AssetImage.GHN,
    ghtk: AssetImage.GHTK,
    vnpost: AssetImage.VnPostLogo,
};

export const ORDER_TYPE = {
    normal: "normal",
    compensation: "compensation",
    exchange: "exchange",
    exchange_return: "exchange_return",
    refund: "refund",
};

export const mapMessageType = {
    all: {
        queryStr: "",
        filterMessage: null,
    },
    only_comment: {
        queryStr: "&is_have_customer_comment=1",
        filterMessage: {is_exclude_message: 1},
    },
    only_message: {
        queryStr: "&is_have_customer_message=1",
        filterMessage: {is_exclude_comment: 1},
    },
};

export const mapInboxState = (sellerId) => ({
    all: "",
    blocked: "&is_block=1",
    mine: "&seller_id=" + sellerId,
    not_taked: "&seller_id=0",
});

export const mapMessageState = {
    all: "",
    not_read: "&is_read=0",
    not_reply: "&is_reply=0",
    have_phone: "&is_have_phone=1",
};

const basePresetData = {
    message_state: [],
    message_type: "all",
    inbox_state: [],
    channels: [],
    tags: [],
    segment: [],
};

export const defaultPresets = [
    {
        id: -1,
        name: "Chưa đọc",
        data: {
            ...basePresetData,
            message_state: ["not_read"],
        },
        is_shared: true,
        icon_class_name: "fas fa-bookmark",
    },
    {
        id: -2,
        name: "Chưa trả lời",
        data: {
            ...basePresetData,
            message_state: ["not_reply"],
        },
        is_shared: true,
        icon_class_name: "fas fa-reply",
    },
    {
        id: -3,
        name: "Có sđt",
        data: {
            ...basePresetData,
            message_state: ["have_phone"],
        },
        is_shared: true,
        icon_class_name: "fas fa-phone",
    },
    {
        id: -4,
        name: "Chỉ hiện comment",
        data: {
            ...basePresetData,
            message_type: "only_comment",
        },
        is_shared: true,
        icon_class_name: "far fa-comment",
    },
    {
        id: -5,
        name: "Chỉ hiện tin nhắn",
        data: {
            ...basePresetData,
            message_type: "only_message",
        },
        is_shared: true,
        icon_class_name: "fas fa-envelope",
    },
];

// get value from object
export const getValue = (path, defaultValue = {}) => (object) =>
{
    return get(object, path, defaultValue);
};

export const isFilterChannel = (channel, filterChannels) =>
{
    console.log("isFilterChannel ", channel, filterChannels);
    if (!filterChannels || !filterChannels.length) return true;
    if (!channel) return false;
    return filterChannels.some(
        (item) => item.id == channel.id && item.platform == channel.platform
    );
};

export const capitalize = (s) =>
{
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const PLATFORMS = {
    tiktok_business: {
        icon: AssetImage.TiktokIcon
    },
    fb: {
        icon: AssetImage.FacebookIcon,
    },
    fb_profile: {
        icon: AssetImage.PersonalFacebookIcon,
    },
    group_fb: {
        icon: AssetImage.GroupFacebookIcon,
    },
    zalo_oa: {
        icon: AssetImage.ZaloOAIcon,
    },
    personal_zalo: {
        icon: AssetImage.ZaloIcon,
    },
    shopee: {
        icon: AssetImage.ShopeeIcon,
    },
    sendo: {
        icon: AssetImage.sendo,
    },
    website: {
        icon: AssetImage.WebsiteIcon,
    },
    email: {
        icon: AssetImage.EmailIcon,
    },
    instagram: {
        icon: AssetImage.InstagramIcon,
    },
    sip: {
        icon: AssetImage.PbxIcon,
    },
}


export const ORDER_PICKING_STATUS = () => {

    let result = {
        all: {
            name: trans(t`Tất cả`),
            color: "info",
            code: "",
        },
        pending: {
            name: trans(t`Chờ xác nhận`),
            color: "warning",
            code: "pending",
        },
        back_ordering: {
            name: trans(t`Đang đặt hàng`),
            color: "warning",
            code: "back_ordering",
        },
        back_ordered: {
            name: trans(t`Đã nhập hàng`),
            color: "rose",
            code: "back_ordered",
        },
        shipping: {
            name: trans(t`Đã xác nhận`),
            color: "primary",
            code: "shipping",
        },
        shipped: {
            name: trans(t`Đã giao hàng`),
            color: "success",
            code: "shipped",
        },
        returning: {
            name: trans(t`Đang trả hàng`),
            color: "warning",
            code: "returning",
        },
        returned: {
            name: trans(t`Đã trả hàng`),
            color: "success",
            code: "returned",
        },
        cancelled: {
            name: trans(t`Đã huỷ`),
            color: "danger",
            code: "cancelled",
        },
    };
    return result;
};

export const ORDER_PAYMENT_STATUS = () => ({
    waiting: {
        name: trans(t`Chưa thanh toán`),
        color: 'danger',
        code: 'waiting',
    },

    paid: {
        name: trans(t`Đã thanh toán`),
        color: 'success',
        code: 'paid',
    },
    refunded: {
        name: trans(t`Đã hoàn tiền`),
        color: 'success',
        code: 'refunded',
    },
});

export const INBOX_LIMIT = 20;
